<template>
  <form>
    <b-card-code title="Atualiza Data de Entrega NF-e">

      <b-row>
        <b-col md="6" xl="5" class="mb-1">
          <b-form-group label="" >
             <b-form-file
                truncate-length="50"
                accept=".xls, .xlsx"
                placeholder="Selecione o arquivo..."
                @change="onChangeDoc"
                ref="DocInput"
              ></b-form-file>
          </b-form-group>
        </b-col>
       

      </b-row>

 
    </b-card-code>

    <div class="card mb-4" v-if="PermiteSalvar">
      <div class="card-body pb-60" >
        <h4 class="fw-bolder mb-1">Detalhe do Arquivo</h4>  
                 <b-table
                  striped
                  hover
                  responsive
                  small
                  sticky-header
                  :items="Tab"
                  selectable
                  select-mode = "single"
                  table-class = "font-size11"
                  thead-class = "font-size11"
                  thead-tr-class = "font-size11"
                >
                </b-table>
     </div>
    </div>
    

    <b-card-code v-if="PermiteExecutar">
      <b-row>
        <b-col md="6" xl="4" class="mb-1">
          <div class="DivBotaoLadoLado">
            <b-button variant="warning" @click="ExecutarImportacao()" >
              <span>EXECUTAR IMPORTAÇÃO</span>
            </b-button>
          </div>
        </b-col>
      </b-row>
    </b-card-code>
  </form>
</template>
<script>
import BCardCode from '@core/components/b-card-code'
import {
  BFormInput, BRow, BCol, BFormGroup, BFormTextarea, BFormDatepicker, BButton,
  BTable, BFormSelect, BPagination,
  BCardBody, BFormFile,
} from 'bootstrap-vue'
import apiPortalSky from '@/services/apiPortalSky'
import AlertaMsg from '@/customPortal/AlertaMsg.vue'
import ConexaoAPI from '@/customPortal/ConexaoAPI.vue'
import UtilVUE from '@/customPortal/UtilVUE.vue'
import Spinner from '@/components/Spinner.vue'
export default {
  components: {
    BCardCode,
    BFormInput,
    BFormGroup,
    BRow,
    BCol,
    BFormTextarea,
    BFormDatepicker,
    BButton,
    BTable,
    BFormSelect,
    BPagination,
    BCardBody,
    BFormFile,
  },
  data() {
    return {
      Tab: [
        {CNPJ_TRANSPORTADORA: 'NNNNNNNNN'},
        {CHAVE_NFE: 'NNNNNNNNN'},
        {DATA_ENTREGA: 'DD/MM/YYYY'}
      ],
      PermiteSalvar: false,
      PermiteExecutar: false,
    }
  },
  methods: {
    async ExecutarImportacao() {
      Spinner.StarLoad()
      this.PermiteExecutar = false
      let notification = ''
      let MsgErro = ''
      
      const usuario = this.$session.get('usuario')
      const URLpost = `/RotinaPorUpload/AtualizaDataEntregaNFe?CODUSU=${usuario.codusu}`
      
    
       const ParametroJson = JSON.stringify(this.Tab)

        await apiPortalSky.post(URLpost, ParametroJson, {
          headers: {
            'Content-Type': 'application/json',
          },
        }).then(response => {
          if (response.status === 200) {
            if(response.data != "Sucesso"){
               this.Tab = []
               this.Tab = response.data
               notification = { message: "Erro ao Realizar a Atualização. Verifique o Detalhe do Arquivo.", alert_type: 'error' }
            } else{
              notification = { message: "Atualização Realizada com Sucesso", alert_type: 'success' }
            }
           
          }
        })
        .catch(error => {
            notification = ConexaoAPI.ValidaErro(error)
          })


       if(notification !== '') {
          AlertaMsg.FormataMsg(notification)
       }else{
          Spinner.EndLoad()
       }

    },
    async onChangeDoc(e) {
     let notification = ''
     let MsgErro = ''
     Spinner.StarLoad()

       this.Tab = []
       this.PermiteSalvar = false
       this.PermiteExecutar = false
       const TabXLSX = await UtilVUE.ReadXLSX(e,0,0)
       this.$refs['DocInput'].reset()
     
      if(TabXLSX.wsdata != null){
          if(TabXLSX.wsdata.length > 0){
            
            if(this.ValidaColunasHead(TabXLSX.wsdata))
            {
              this.PermiteSalvar = true
              this.PermiteExecutar = true

              this.CarregaTab(TabXLSX.wsdata)
              
              
            }
            else{
              MsgErro = `Documento Enviado não esta no Formato esperado. Favor verificar Modelo Base.`
              notification = { message: MsgErro, alert_type: 'error' }
            }

          }
      }

       if(notification !== '') {
          AlertaMsg.FormataMsg(notification)
       }else{
          Spinner.EndLoad()
       }

    },
  ValidaColunasHead(wsdata){
    let Validado = false

    if(wsdata[0].CNPJ_TRANSPORTADORA != null && 
       wsdata[0].CHAVE_NFE != null && 
       wsdata[0].DATA_ENTREGA != null
      )
      {
           Validado = true;
      }


      return Validado;
     
    },
    CarregaTab(wsdata){
      
      /* --Exemplo caso queira pegar somente algumas colunas
      for (var prop in wsdata) {
                    // console.log("propriedade: " + prop + " valor: " + wsdata[prop].NomeColuna)
          }
      */
      
      /*Exemplo como carregar toda a Tabela
      this.Tab = wsdata
      */

      let Carregar = true
     
      
     for (var prop in wsdata) {         
           
           Carregar = true

           let Dados = {
            CNPJ_TRANSPORTADORA: '',
            CHAVE_NFE: '',
            DATA_ENTREGA: '',
              
            }


           if(Carregar == true){
               Dados.CNPJ_TRANSPORTADORA = wsdata[prop].CNPJ_TRANSPORTADORA
               Dados.CHAVE_NFE = wsdata[prop].CHAVE_NFE
               Dados.DATA_ENTREGA =   wsdata[prop].DATA_ENTREGA 
                 
               this.Tab.push(Dados)
           }

           
         }


    },

  },
 
  mounted() {

  },
}
</script>
